<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-xxl-9">
      <div class="card mt-xxl-n5">
        <div class="card-body p-4">
          <div class="tab-content">
            <div class="tab-pane active" id="personalDetails" role="tabpanel">
              <form>
                <div class="row">
                  <div class="col-lg-4">
                    <label for="fac" class="form-label">Filière</label>
                    <Multiselect id="fac" class="form-control" v-model="data.faculty"
                      :class="{ 'is-invalid': v$.data.faculty.$error && data.faculty || v$.data.faculty.$error && submited, }"
                      :close-on-select="true" :searchable="false" :show-labels="false" :options="facultiesOptions" />
                    <div v-for="(item, index) in v$.data.faculty.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message && data.faculty || v$.data.faculty.$error && submited">{{ item.$message
                      }}</span>
                    </div>
                  </div>


                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="sem" class="form-label">Semestre</label>
                      <Multiselect id="sem" class="form-control" v-model="data.Semestre"
                        :class="{ 'is-invalid': v$.data.Semestre.$error && data.Semestre || v$.data.Semestre.$error && submited, }"
                        :close-on-select="true" :searchable="false" :show-labels="false" :options="SemestresOptions" />
                      <div v-for="(item, index) in v$.data.Semestre.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message && data.Semestre || v$.data.Semestre.$error && submited">{{
                          item.$message }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-xxl-1 col-sm-4">
                    <div class="m-h-28">
                      <button type="button" class="btn btn-primary w-100 btn-pd-t"
                        :disabled="data?.faculty?.length === 0 || data?.Semestre?.length === 0" @click="filterAdmitions">
                        <i class="ri-equalizer-fill me-1 align-bottom"></i>
                        Filtrer
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-xxl-9">
                  <div class="card mt-xxl-n5">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">matricule</th>

                          <th scope="col">Nom complet</th>

                          <th scope="col">Action</th>

                        </tr>

                      </thead>

                      <tbody>
                        <tr v-for="student in filteredAdmitions" :key="student.id">
                          <th scope="row">{{ student.matricule }}</th>

                          <td>{{ student.name }}</td>

                          <td>
                            <div class="hstack gap-3 flex-wrap">
                              <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target=".bs-example-modal-xl"
                                @click="getReleves(student.id, student, this.data.Semestre)" class="link-success fs-18"><i
                                  class="mdi mdi-file-eye-outline"></i></a>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
                <!--end row-->
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade bs-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="cont" id="releve">
                <div>
                  <p class="MsoNormal"><span style='position:relative;z-index:251660288'>
                    <span style='position:absolute;left:2px;top:-20px;width:735px;height:111px'>
                        <img width=840 height=111 src="../../assets/images/image001.jpg"></span>
                    </span>&nbsp;
                </p>
        
                <p class="MsoNormal">&nbsp;</p>
        
                <br>
        
                <p class="MsoNormal" style='text-align:center;padding-top:25px;'>
                    <b>
                        <span style='font-size:16.0pt;line-height:107%;font-family:"Times New Roman",serif'>
                            RELEVE DE NOTES
                        </span>
                    </b>
                </p>
        
                <p class="MsoNormal" style='margin-bottom:0cm;text-align:center'>
                    <span style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif'>
                        LICENCE PROFESSIONNELLE
                    </span>
                </p>
        
                <p class="MsoNormal" style='margin-bottom:0cm;text-align:center'>
                    <span style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif'>
                        SYSTEMES INFORMATIQUES ET LOGICIEL
                    </span>
                </p>
        
                <p class="MsoNormal" style='margin-bottom:0cm;text-align:center'>
                    <span style='font-size:10.0pt;line-height:107%;font-family:"Times New Roman",serif'>
                        ANNEE ACADEMIQUE {{ schoolYear }}
                    </span>
                </p>
        
                <p class="MsoNormal" style='margin-bottom:0cm;text-align:center;margin-top: 20px; line-height:normal'>
                    <img width=120 height=3 src="../../assets/images/image003.png">
                    <span style='font-size:10.0pt;font-family:"Times New Roman",serif'>&nbsp;</span>
                </p>
        
                <p class="MsoNormal" style='margin-bottom:0cm;text-align:center; line-height:normal'>
                        <span v-if="data.Semestre == 1" style='font-size:15.0pt;font-family:"Times New Roman",serif'>1
                          <sup>er</sup>&nbsp;semestre
                        </span>
                        <span v-else-if="data.Semestre !== 1" style='font-size:15.0pt;font-family:"Times New Roman",serif'>{{ data.Semestre }}
                          <sup>ème</sup>&nbsp;semestre
                        </span>
                </p>
        
                <p class="MsoNormal" style='margin-bottom:0cm;line-height:normal'>
                    <span style='font-size:10.0pt;font-family:"Times New Roman",serif'>&nbsp;</span>
                </p>
        
                <div class="MsoNormal" style='margin-bottom:0cm;line-height:normal;display: flex;'>
                    <span style='font-size:10.0pt;font-family:"Times New Roman",serif'>
                        NOM ET PRENOMS&nbsp;: M. {{ currentStudent.name }} né(e) le {{ currentStudent.birth_date }} à {{ currentStudent.birth_loc }}
                    </span>
                    <div style="text-align: right; position: absolute; margin-left: 650px;">
                        <span style='font-size:10.0pt; font-family:"Times New Roman",serif'>
                            N<sup>o</sup>Mle&nbsp;: {{ currentStudent.matricule }}
                        </span>
                    </div>
                </div>
                  <table width=840 style="border-collapse: collapse; border: medium none;">
                    <tbody>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: solid solid none; border-width: 1pt 1pt medium; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 25.45pt; min-width: 190px; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>&nbsp;</span>
                            </strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Unités d'enseignement
                                (UE)</span>
                            </strong>
                          </p>
                        </td>

                        <td
                          style="border-color: currentcolor currentcolor; border-style: solid solid none none; border-width: 1pt 1pt medium medium; padding: 0cm 5.4pt; height: 25.45pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>&nbsp;</span>
                            </strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Crédits</span>
                            </strong>
                          </p>
                        </td>

                        <td
                          style="width: 10.1243%; border-color: currentcolor; border-style: solid solid solid none; border-width: 1pt 1pt 1pt medium; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 25.45pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Notes</span>
                            </strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>/20</span>
                            </strong>
                          </p>
                        </td>

                        <td
                          style="width: 9.0182%; border-color: currentcolor; border-style: solid solid solid none; border-width: 1pt 1pt 1pt medium; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 25.45pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Notes</span>
                            </strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>/40</span>
                            </strong>
                          </p>
                        </td>

                        <td
                          style="width: 9.4473%; border-color: currentcolor; border-style: solid solid solid none; border-width: 1pt 1pt 1pt medium; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 25.45pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Notes</span>
                            </strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>/20</span>
                            </strong>
                          </p>
                        </td>

                        <td
                          style="width: 7.3191%; border-color: currentcolor currentcolor; border-style: solid solid none none; border-width: 1pt 1pt medium medium; padding: 0cm 5.4pt; height: 25.45pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Moyenne</span>
                            </strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Coef.</span>
                            </strong>
                          </p>
                        </td>

                        <td
                          style="width: 8.2774%; border-color: currentcolor currentcolor; border-style: solid solid none none; border-width: 1pt 1pt medium medium; padding: 0cm 5.4pt; height: 25.45pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>&nbsp;</span>
                            </strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Résultat</span>
                            </strong>
                          </p>
                        </td>

                        <td
                          style="width: 8.617%; border-color: currentcolor currentcolor; border-style: solid solid none none; border-width: 1pt 1pt medium medium; padding: 0cm 5.4pt; height: 25.45pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Crédits</span>
                            </strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>obtenus</span>
                            </strong>
                          </p>
                        </td>

                        <td
                          style="border-color: currentcolor currentcolor; border-style: solid solid none none;  min-width: 190px; border-width: 1pt 1pt medium medium; padding: 0cm 5.4pt; height: 25.45pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong>
                          </p>
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span
                                style='font-size:13px;font-family:"Times New Roman",serif;'>Enseignants</span></strong>
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 10.1243%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Devoir</span>
                            </strong>
                          </p>
                        </td>
                        <td
                          style="width: 9.0182%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>Examen</span>
                            </strong>
                          </p>
                        </td>
                        <td
                          style="width: 9.4473%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>DevEx</span></strong>
                          </p>
                        </td>
                        <td
                          style="width: 7.3191%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 8.2774%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 8.617%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 14.0372%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 7.1pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>UE FONDAMENTALES</span>
                            </strong>
                          </p>
                        </td>
                        <td colspan="8" class="bg-color"
                          style="width: 78.5093%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <span style='font-family:"Times New Roman",serif;'>MATIERE 1</span>
                          </p>
                        </td>
                        <td
                          style="border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 10.1243%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;</p>
                        </td>
                        <td
                          style="width: 9.0182%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 9.4473%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;</p>
                        </td>
                        <td
                          style="width: 7.3191%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;</p>
                        </td>
                        <td
                          style="width: 8.2774%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <span style='font-size:13px;font-family:"Times New Roman",serif;'>V</span>
                          </p>
                        </td>
                        <td
                          style="width: 8.617%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 14.0372%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <span style='font-size:13px;font-family:"Times New Roman",serif;'>M. John DOE 1</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>TOTAL</span></strong>
                          </p>
                        </td>
                        <td
                          style="border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>00</span></strong>
                          </p>
                        </td>
                        <td colspan="3" class="bg-color"
                          style="width: 28.5094%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 7.319%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>000000</span></strong>
                          </p>
                        </td>
                        <td class="bg-color"
                          style="width: 8.2774%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 8.617%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>00</span></strong>
                          </p>
                        </td>
                        <td class="bg-color"
                          style="width: 14.0372%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                            <strong><em><span style='font-size:13px;font-family:"Times New Roman",serif;'>Moyenne
                                  UE</span></em></strong>
                          </p>
                        </td>
                        <td colspan="2"
                          style="width: 20.9938%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>0000</span></strong>
                          </p>
                        </td>
                        <td colspan="6" class="bg-color"
                          style="width: 58.3203%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>UE
                                COMPLEMENTAIRES</span></strong>
                          </p>
                        </td>
                        <td colspan="8" class="bg-color"
                          style="width: 78.5093%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <span style='font-family:"Times New Roman",serif;'>MATIERE 2</span>
                          </p>
                        </td>
                        <td
                          style="border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 10.1243%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 9.0182%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 9.4473%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 7.3191%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 8.2774%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <span style='font-size:13px;font-family:"Times New Roman",serif;'>V</span>
                          </p>
                        </td>
                        <td
                          style="width: 8.617%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            &nbsp;
                          </p>
                        </td>
                        <td
                          style="width: 14.0372%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <span style='font-size:13px;font-family:"Times New Roman",serif;'>M. John DOE 2</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;TOTAL</span></strong>
                          </p>
                        </td>
                        <td
                          style="border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>00</span></strong>
                          </p>
                        </td>
                        <td colspan="3" class="bg-color"
                          style="width: 28.5094%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 7.319%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>000000</span></strong>
                          </p>
                        </td>
                        <td class="bg-color"
                          style="width: 8.2774%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 8.617%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>00</span></strong>
                          </p>
                        </td>
                        <td class="bg-color"
                          style="width: 14.0372%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                            <strong>
                              <em>
                                <span style='font-size:13px;font-family:"Times New Roman",serif;'>******* UE</span>
                              </em>
                            </strong>
                          </p>
                        </td>
                        <td colspan="2"
                          style="width: 20.9938%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>0000</span></strong>
                          </p>
                        </td>
                        <td colspan="6" class="bg-color"
                          style="width: 58.3203%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                            <strong>
                              <em>
                                <span style='font-size:13px;font-family:"Times New Roman",serif;'>Moyenne S5</span>
                              </em>
                            </strong>
                          </p>
                        </td>
                        <td colspan="2"
                          style="width: 20.9938%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>0000</span></strong>
                          </p>
                        </td>
                        <td colspan="2" class="bg-color"
                          style="width: 19.0789%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 7.319%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong>
                              <span style='font-size:13px;font-family:"Times New Roman",serif;'>000000</span>
                            </strong>
                          </p>
                        </td>
                        <td class="bg-color"
                          style="width: 8.2774%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 8.617%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>00</span></strong>
                          </p>
                        </td>
                        <td class="bg-color"
                          style="width: 14.0372%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                            <strong>
                              <em>
                                <span style='font-size:13px;font-family:"Times New Roman",serif;'>Total Crédits S3+S4</span>
                              </em>
                            </strong>
                          </p>
                        </td>
                        <td colspan="6" class="bg-color"
                          style="width: 55.8551%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 8.6169%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>00</span></strong>
                          </p>
                        </td>
                        <td
                          style="width: 14.0372%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 15.65pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="border-color: currentcolor; border-style: none solid solid; border-width: medium 1pt 1pt; border-image: none 100% / 1 / 0 stretch; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                            <strong>
                              <em>
                                <span style='font-size:13px;font-family:"Times New Roman",serif;'>Total Crédits S1+S2</span>
                              </em>
                            </strong>
                          </p>
                        </td>
                        <td colspan="6" class="bg-color"
                          style="width: 55.8551%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; background: rgb(231, 230, 230); padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                        <td
                          style="width: 8.6169%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
                            <strong><span style='font-size:13px;font-family:"Times New Roman",serif;'>00</span></strong>
                          </p>
                        </td>
                        <td
                          style="width: 14.0372%; border-color: currentcolor currentcolor; border-style: none solid solid none; border-width: medium 1pt 1pt medium; padding: 0cm 5.4pt; height: 14.9pt; vertical-align: top;">
                          <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'>
                            <strong>&nbsp;</strong>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="javascript:void(0);" class="btn btn-link link-success fw-medium" data-bs-dismiss="modal">
                <i class="ri-close-line me-1 align-middle"></i> Close
              </a>
              <button type="button" class="btn btn-primary" @click="printReleve">Imprimer</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

    </div>
  </Layout>
</template>

<style scoped>
.cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-h-28 {
  margin-top: 28px;
}

.btn-pd-t {
  padding-top: 10px;
}
</style>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
// import Swal from "sweetalert2";
import print from 'print-js'


export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      data: {
        faculty: '',
        // Year: '',
        Semestre: '',
      },
      faculties: {},
      facultiesOptions: [],
      studentNotes: [],
      admitions: [],
      filteredAdmitions: [],
      MainMatters: [],
      SecondaryMatters: [],
      currentExamId: null,
      schoolYear: localStorage.getItem('selectedYear'),
      currentStudent: [],
      students: [],
      title: "Relevés Notes",
      SemestresOptions: [
        { value: 1, label: 'semestre 1' },
        { value: 2, label: 'semestre 2' },
      ],
      items: [
        {
          text: "Notes",
          href: "/",
        },
        {
          text: "Notes",
          active: true,
        },
      ],
    };
  },
  validations: {
    data: {
      faculty: {
        required: helpers.withMessage("Veuillez selectionnez une filière", required),
      },
      Semestre: {
        required: helpers.withMessage("Veuillez selectionnez un année scolaire", required),
      },
      Year: {
        required: helpers.withMessage("Veuillez selectionnez un année scolaire", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.getAllFaculties();
    this.getAdmissions();
  },
  methods: {
    getAllFaculties() {
      const self = this;
      this.$store.dispatch('getRequest', { route: '/api/getAllFaculties', data: this.data })
        .then(
          function (response) {
            self.faculties = response.data.filieres;

            self.faculties.forEach(element => {

              self.facultiesOptions.push({ value: element.id, label: element.code });

            });
          },
          function (error) {
            console.log(error);
          }
        )
    },

    filterAdmitions() {
      this.filteredAdmitions = this.admitions.filter(admition => admition.faculty_id === this.data.faculty && admition.promotion === localStorage.getItem('selectedYear'));
    },

    getAdmissions() {
      const self = this;
      this.$store.dispatch('getRequest', { route: '/api/fetchAdmitions', data: this.data })
        .then(
          function (response) {
            self.admitions = response.data.admitions;
          },
          function (error) {
            console.log(error);
          }
        )
    },

    getReleves(student_id, student, semestre) {
      this.currentStudent = student;
      const self = this;
      this.$store.dispatch('getRequest', { route: `/api/makeNoteTranscript/${student_id}/${semestre}`, data: this.data })
        .then(
          function (response) {
            self.studentNotes = response.data;
            console.log(self.currentStudent);
          },
          function (error) {
            console.log(error);
          }
        );
    },

    filterMainMatters() {

    },

    filterSecondaryMatters() {
      // this.secondaryMatters = tableau.filter(element => element.typeUE === "C");
    },


    printReleve() {
      print({
        printable: 'releve',
        type: 'html',
        css: '/src/views/students/releve.css',
        scanStyles: false,
      });
    }


  },
};
</script>
  
  